.App {
  font-family: 'Roboto', sans-serif;
}

.dropzone {
  border: 2px dashed #1976d2;
  padding: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dropzone:hover {
  background-color: #f0f0f0;
}

.accordion-summary {
  background-color: #e3f2fd;
}

.accordion-details {
  background-color: #f9f9f9;
}

button {
  text-transform: none;
}

.MuiChip-root {
  margin-top: 10px;
}

.MuiAlert-root {
  margin-top: 20px;
}

.highlight {
  background-color: #e8f0fe !important;
  border: 1px solid #1976d2 !important;
}

.code-generation-accordion {
  background-color: #e8f0fe !important;
  border: 1px solid #1976d2 !important;
}